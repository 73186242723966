// Common Sass
$eggshell: #f6f3f0;
$darkEggshell: #e8dacb;
$darkerEggshell: #f0e8df;
$secondaryEggshell: #f5f3ee;
$blue: #2374e1;
$plum: #502d3c;
$lightPlum: #663c4e;
$darkPlum: #38202a;
$plum-100: #f0edee;
$plum-200: #e5d8de;
$plum-300: #c4a7b6;
$plum-400: #a17a8b;
$plum-500: #825568;
$plum-600: #663d4f;
$plum-800: #402530;
$transparentDarkPlum: rgba(56, 32, 42, 0.7);
$darkUnsaturatedPlum: #301923;
$white: #fff;
$lightYellow: #f8e5c2;
$primaryYellow: #fdb71c;
$secondaryYellow: #f8e5c2;
$secondaryNeutral: #6684ef;
$yellow: #fdb71c;
$secondaryRed: #d75e4c;
$tertiary-gray-1: #f5f5f5;
$tertiary-gray-2: #e0dede;
$tertiary-gray-3: #c7c5c5;
$tertiary-gray-4: #8c8888;
$tertiary-gray-5: #595555;
$tertiary-negative: #C3402C;
$tertiary-positive: #20968f;
