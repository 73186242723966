@import 'assets/scss/_common.scss';

.alert {
  justify-content: flex-start;
}

.logo {
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 60px;
  width: 60px;
  z-index: 9999;
  border-radius: 50%;
  border: 1px solid $eggshell;
  background-color: $lightPlum;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $darkPlum;
  }

  > * {
    margin: 5px;
  }
}

.link {
  text-decoration: underline;
  align-self: flex-end;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: $white;
  width: 400px;
  min-height: 400px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.revalidationSection {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-size: 20px;
  }

  input[name="secret"] {
    padding: 12px 16px;
    border-radius: 8px;
    border: solid 1px $tertiary-gray-2;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.65);
}